.form-data {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;
}

.form-member-edit-data {
    
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formDelData {
    
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formSearchData {
    
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formCreateData {
    
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;
}

.formCreateDataFriday {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formEditData {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formDelDataFriday {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.form-wheel-edit-data {
    
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formEditDataCompany {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;
}

.formDelDataCompany {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formCreateDataGames {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}
.formDelDataGames {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formCreateDataServiceAccount {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;
}

.formEditDataGames {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formEditDataSeviceAccount {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}
.formDelDataServiceAccount {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formCreateDataProduct {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formEditDataProduct {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formDelDataProduct {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}


.formCreateDataCards {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;
}

.formEditDataCards {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}

.formDelDataCards {
    
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;
}


.formEditExchanheData {

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: -100%;
    overflow: auto;
    transition: all .5s ease-in-out;

}


.box-data {

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content:space-evenly;
    width: 25rem;
    height: 70%;
    background: #9d9191;
    left: 0;
    top: 0;
    margin: auto;
    border-radius: 5px;
}


.box-data-small {

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content:space-evenly;
    width: 25rem;
    height: 30%;
    background: #9d9191;
    left: 0;
    top: 0;
    margin: auto;
    border-radius: 5px;
}

.box-data-height {

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content:space-evenly;
    width: 25rem;
    height: 70%;
    background: #9d9191;
    left: 0;
    top: 0;
    margin: auto;
    border-radius: 5px;
}

.box-data-search {

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content:space-evenly;
    width: 25rem;
    height: 30%;
    background: #35bf7f;
    left: 0;
    top: 0;
    margin: auto;
    border-radius: 5px;
}

.form-input {

    width: 100%;
    margin: 0;
    padding-top: 10px;
    display: grid;
    justify-items: start;
    padding-left: 50px;
    color: #fff;
}

.form-control {

    height: 40px;
    width: 20rem;
    border-radius: 5px;
    border: 0.5rem;
    padding: 10px;
    font: small-caption;
}

.form-input-btn {

    display: flex;
    justify-content: space-evenly;
    width: 100%;

}


.form-btn {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

}

.bg-color-brow {
    background-color: brown;
}

