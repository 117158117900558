.text-ar-group {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 50px;
}

.rules-st {
    width: 800px;
    height: 800px;
}