.templete-table-code {

    background: rgb(136, 136, 136);
    margin: 5px;
    color: #fff;

}

.header-table {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.text-header {
    padding-left: 10px;
    font-size:17px;
    cursor: default;
}

.btn {

    margin: 1px;
    border-radius: 5px;
    display: inline-block;
    font: small;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;


}

.btn-games {

    margin-right: 5px;
}

.text-white {

    color: #fff;
    font-size: small;

}

.text-status-on {

    color: #72d70d;

}

.text-status-off {

    color: #ff0c00;

}



.btnback{

    cursor:pointer;
    background-color: crimson;
    border-color:crimson;
}

.btnedit{

    cursor:pointer;
    background-color: #eab13c;
    border-color:#eab13c;
}


.btndel {

    cursor:pointer;
    background-color: #c8371e;
    border-color:#c8371e;
}


.btncreate {

    cursor:pointer;
    color: #45b331;
    background-color: #ffffff;
    border-color:#ffffff;
}

.btncancel {

    cursor:pointer;
    color: #c8371e;
    background-color: #ffffff;
    border-color:#ffffff;
}

.btnpercent {

    cursor:pointer;
    color: #c8371e;
    background-color: #46a5c1;
    border-color:#ffffff;
}

.btnsecrate {

    cursor:pointer;
    color: #c8371e;
    background-color: #b949cd;
    border-color:#ffffff;
}

.input-file {

    background-color: #ffb27f;

}

.btn-ef:hover {
    transition-duration: 0.4s;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    transform: scale(1.03);
    background-color: #93a399;
    color: #fff;
}
