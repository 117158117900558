.imgSizeNormal {
    width: 150px;
    height: auto;
}

.text-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 12px;
    cursor: default;
    padding-top: 10px;

}
.text-side .text {
    margin-left: 10px;
}

.text-side .text .text-number {
    display: contents;
    color: #fff;
}

.text-mini{
    margin-right: 10px;
    margin-top: 10px;
}

.field-input {
    width: auto;
    padding: 20px 0 0 0;
}

.input-content-side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.input-content-side .text-center {
    width: 50px;
    height: auto;
    text-align: center;
}